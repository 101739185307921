"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createContainer = void 0;

var _victoryCreateContainer = require("victory-create-container");

var _victoryContainer = require("../components/victory-container");

var _victoryZoomContainer = require("../components/victory-zoom-container");

var _victoryVoronoiContainer = require("../components/victory-voronoi-container");

var _victorySelectionContainer = require("../components/victory-selection-container");

var _victoryBrushContainer = require("../components/victory-brush-container");

var _victoryCursorContainer = require("../components/victory-cursor-container");

var createContainer = (0, _victoryCreateContainer.makeCreateContainerFunction)({
  zoom: [_victoryZoomContainer.zoomContainerMixin],
  voronoi: [_victoryVoronoiContainer.voronoiContainerMixin],
  selection: [_victorySelectionContainer.selectionContainerMixin],
  brush: [_victoryBrushContainer.brushContainerMixin],
  cursor: [_victoryCursorContainer.cursorContainerMixin]
}, _victoryContainer.VictoryContainer);
exports.createContainer = createContainer;
var _default = createContainer;
exports.default = _default;