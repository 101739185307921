"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeThrottledHandler = exports.default = exports.Helpers = void 0;

var _defaults2 = _interopRequireDefault(require("lodash/defaults"));

var _throttle2 = _interopRequireDefault(require("lodash/throttle"));

var _reactNative = require("react-native");

var _reactFastCompare = _interopRequireDefault(require("react-fast-compare"));

var _victoryCore = require("victory-core");

var _victoryZoomContainer = require("victory-zoom-container");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var hypotenuse = function (x, y) {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
};

var screenSize = hypotenuse(_reactNative.Dimensions.get("window").width, _reactNative.Dimensions.get("window").height);

var Helpers = _objectSpread(_objectSpread({}, _victoryZoomContainer.RawZoomHelpers), {}, {
  onTouchEnd: function () {
    return [{
      target: "parent",
      mutation: function () {
        return {
          panning: false,
          originalPinchDistance: null
        };
      }
    }];
  },
  // eslint-disable-next-line max-params
  onTouchPinch: function (evt, targetProps, eventKey, ctx) {
    var onZoomDomainChange = targetProps.onZoomDomainChange,
        zoomDimension = targetProps.zoomDimension,
        domain = targetProps.domain,
        zoomDomain = targetProps.zoomDomain;
    var touches = evt.nativeEvent.touches;

    if (!targetProps.allowZoom) {
      return {};
    }

    var originalDomain = this.getDomain(targetProps);
    var lastDomain = (0, _defaults2.default)({}, targetProps.currentDomain || zoomDomain || originalDomain, domain);
    var x = lastDomain.x,
        y = lastDomain.y;
    var currentDomain = {
      x: zoomDimension === "y" ? lastDomain.x : this.scaleNative(x, evt, targetProps, "x"),
      y: zoomDimension === "x" ? lastDomain.y : this.scaleNative(y, evt, targetProps, "y")
    };
    var resumeAnimation = this.handleAnimation(ctx);
    var pinchDistance = this.getPinchDistance(touches);
    var originalPinchDistance = targetProps.originalPinchDistance || pinchDistance;
    var zoomActive = pinchDistance !== originalPinchDistance || // if zoomActive is already set AND user hasn't zoommed out all the way
    targetProps.zoomActive && !(0, _reactFastCompare.default)(originalDomain, lastDomain);

    if (_victoryCore.Helpers.isFunction(onZoomDomainChange)) {
      onZoomDomainChange(currentDomain);
    }

    return [{
      target: "parent",
      callback: resumeAnimation,
      mutation: function () {
        return {
          domain: currentDomain,
          currentDomain: currentDomain,
          originalDomain: originalDomain,
          cachedZoomDomain: zoomDomain,
          parentControlledProps: ["domain"],
          panning: false,
          originalPinchDistance: originalPinchDistance,
          zoomActive: zoomActive
        };
      }
    }];
  },
  getPinchDistance: function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        a = _ref2[0],
        b = _ref2[1];

    return hypotenuse(b.locationX - a.locationX, b.locationY - a.locationY);
  },
  getScaleFactorNative: function (evt, props) {
    var touches = evt.nativeEvent.touches;
    var originalPinchDistance = props.originalPinchDistance || 0;
    var currentPinchDistance = this.getPinchDistance(touches);
    var scaledPinchChange = (currentPinchDistance - originalPinchDistance) / screenSize;
    return 1 - scaledPinchChange;
  },
  // eslint-disable-next-line max-params
  scaleNative: function (currentDomain, evt, props, axis) {
    var _currentDomain = _slicedToArray(currentDomain, 2),
        from = _currentDomain[0],
        to = _currentDomain[1];

    var range = Math.abs(to - from);
    var minimumZoom = props.minimumZoom && props.minimumZoom[axis];
    var factor = this.getScaleFactorNative(evt, props);

    if (minimumZoom && range <= minimumZoom && factor < 1) {
      return currentDomain;
    }

    var _this$getDomain$axis = _slicedToArray(this.getDomain(props)[axis], 2),
        fromBound = _this$getDomain$axis[0],
        toBound = _this$getDomain$axis[1];

    var percent = this.getScalePercent(evt, props, axis);
    var point = factor * from + percent * (factor * range);
    var minDomain = this.getMinimumDomain(point, props, axis);

    var _this$getScaledDomain = this.getScaledDomain(currentDomain, factor, percent),
        _this$getScaledDomain2 = _slicedToArray(_this$getScaledDomain, 2),
        newMin = _this$getScaledDomain2[0],
        newMax = _this$getScaledDomain2[1];

    var newDomain = [newMin > fromBound && newMin < toBound ? newMin : fromBound, newMax < toBound && newMax > fromBound ? newMax : toBound];
    var domain = Math.abs(minDomain[1] - minDomain[0]) > Math.abs(newDomain[1] - newDomain[0]) ? minDomain : newDomain;
    return _victoryCore.Collection.containsDates([fromBound, toBound]) ? [new Date(domain[0]), new Date(domain[1])] : domain;
  }
});

exports.Helpers = Helpers;

var makeThrottledHandler = function (handler) {
  // eslint-disable-next-line no-magic-numbers
  var throttledHandler = (0, _throttle2.default)(handler, 16, {
    leading: true
  });
  return function (evt) {
    evt.persist(); // ensure that the react native event is persisted!

    for (var _len = arguments.length, otherParams = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      otherParams[_key - 1] = arguments[_key];
    }

    return throttledHandler.apply(void 0, [evt].concat(otherParams));
  };
};

exports.makeThrottledHandler = makeThrottledHandler;
var _default = {
  onTouchStart: Helpers.onMouseDown.bind(Helpers),
  onTouchEnd: Helpers.onTouchEnd.bind(Helpers),
  onTouchMove: makeThrottledHandler(Helpers.onMouseMove.bind(Helpers)),
  onTouchPinch: makeThrottledHandler(Helpers.onTouchPinch.bind(Helpers))
};
exports.default = _default;